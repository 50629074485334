import { Helmet } from 'react-helmet'

// eslint-disable-next-line @typescript-eslint/ban-types
export default function Viewport({ children }: React.PropsWithChildren<{}>) {
  return (
    <Helmet>
      {/* To ensure proper rendering and touch zooming for all devices, add the responsive viewport meta tag to your <head> element. */}
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      {children}
    </Helmet>
  )
}
